.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#signal-strength {
  height: 100px;
  list-style: none;
  overflow: hidden;
  /* background: orange; */
  /* margin-left: 20%; */
}
#signal-strength li {
  display: inline-block;
  width: 15px;
  float: left;
  height: 100%;
  margin-right: 1px;
}
/* #signal-strength li.signal-10 {
  padding-top: 10px;
}
#signal-strength li.signal-20 {
  padding-top: 20px;
}
#signal-strength li.signal-30 {
  padding-top: 30px;
}
#signal-strength li.signal-40 {
  padding-top: 40px;
}
#signal-strength li.signal-50 {
  padding-top: 50px;
}
#signal-strength li.signal-60 {
  padding-top: 60px;
}
#signal-strength li.signal-70 {
  padding-top: 70px;
}
#signal-strength li.signal-80 {
  padding-top: 80px;
}
#signal-strength li.signal-90 {
  padding-top: 90px;
}
#signal-strength li.signal-100 {
  padding-top: 0px;
} */
#signal-strength li div {
  height: 100%;
  background: black;
}

.score-label {
  color:#ffffff;
  font-size: 2vw;
  position: absolute;
}

.extra-width {
  width: 100% !important;
}
